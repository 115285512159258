<template>
  <div>
    <StorageTakeout :inout-option="inoutOption" />
  </div>
</template>
<script>
import StorageTakeout from './StorageTakeout'
// import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'StorageBadTakeout',
  components: {
    StorageTakeout
  },
  data: function () {
    return {
      inoutOption: ConstDef.불량폐기
    }
  },
  created () {

  },
  mounted () {

  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
